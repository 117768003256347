import {
  ActionButton,
  FormLabel,
  FormSingleSelect,
  FormTextField,
  Media,
  MediaRow,
  axiosErrorToast,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Typography, alpha, styled } from '@mui/material'
import ImageUpload from 'components/ImageUpload'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'
import * as yup from 'yup'
import { createApp } from './services'
const schema = yup.object().shape({
  tenantId: yup.string().required('Tenant Name is required'),
  appName: yup.string().required('App Name is required'),
  primaryColor: yup.string().required('Primary Color is required'),

  colorBeginGradient: yup.string().required('ColorBeginGradient is required'),
  colorEndGradient: yup.string().required('ColorEndGradient is required'),

  collegeAppName: yup.string().required('College AppName is required'),
  collegeShortName: yup.string().required('College ShortName is required'),
  collegeName: yup.string().required('CollegeName is required'),
})

function AppForm({ data, hideDialog }: { data?: any; hideDialog: () => void }) {
  const queryClient = useQueryClient()
  const { control, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
    },
  })
  const [logo, setLogo] = useState<Media[]>([])
  const [homeIcon, setHomeIcon] = useState<Media[]>([])
  useEffect(() => {
    if (!data) return
    setLogo([
      {
        key: data?.logo?.key,
        url: data?.logo?.url,
        type: 'image',
      },
    ])
    setHomeIcon([
      {
        key: data?.homeIcon?.key,
        url: data?.homeIcon?.url,
        type: 'image',
      },
    ])
  }, [data])

  const { data: fetchTenats, isLoading: tentsloading } = useQuery(
    'tenants',
    SERVICES.tenant.fetchAll,
  )
  const { mutate, isLoading } = useMutation(createApp, {
    onSuccess: (res) => {
      toast.success(
        data ? 'App Updated Successfully' : 'App created successfully',
      )
      queryClient.invalidateQueries('fetch-apps')
      hideDialog()
    },
    onError: (err) => {
      // eslint-disable-next-line no-console
      console.log(err)
      axiosErrorToast(err)
    },
  })

  const onSubmit = (formdata) => {
    if (!logo.length) {
      toast.warn('Please upload an Logo')
      return
    }
    if (!homeIcon.length) {
      toast.warn('Please upload an HomeIcon')
      return
    }
    mutate({
      ...formdata,
      logoKey: logo[0]?.key,
      homeIconKey: homeIcon[0]?.key,
    })
  }

  const handleLogoChange = (v) => {
    setLogo([{ type: 'image', url: v.url, key: v.key }])
  }
  const handleDeletelogo = (key) => {
    setLogo((prev) => prev.filter((m) => m.key !== key))
  }
  const handleHomeIconChange = (v) => {
    setHomeIcon([{ type: 'image', url: v.url, key: v.key }])
  }

  const handleDeleteHomeIcon = (key) => {
    setHomeIcon((prev) => prev.filter((m) => m.key !== key))
  }
  const onerror = (err) => {
    console.log(err, 'this is err')
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onerror)}>
        <Stack gap={2.5}>
          <FormSingleSelect
            label="College"
            name="tenantId"
            control={control}
            options={fetchTenats?.result?.map((tenant) => ({
              label: tenant.name,
              value: tenant._id,
            }))}
            required
            disabled={data}
          />
          <FormSingleSelect
            label={'App Name'}
            name={'appName'}
            control={control}
            required
            options={[
              {
                label: 'Student App',
                value: 'campx-student-app',
              },
              {
                label: 'Faculty App',
                value: 'campx-faculty-app',
              },
            ]}
            disabled={data}
          />
          <FormTextField
            label={'Primary Color'}
            name={'primaryColor'}
            control={control}
            required
          />
          <FormTextField
            label={'Color BeginGradient'}
            name={'colorBeginGradient'}
            control={control}
            required
          />
          <FormTextField
            label={'Color EndGradient'}
            name={'colorEndGradient'}
            control={control}
            required
          />

          {!logo?.length ? (
            <>
              <FormLabel label={'Logo Key'} name={'logoKey'} required={true} />
              <ImageUpload
                onFileUploaded={handleLogoChange}
                postUrl="/app-themes/upload"
                postBody={{
                  subType: 'logos',
                  tenantId: watch('tenantId'),
                }}
              />
            </>
          ) : (
            <>
              <FormLabel
                label={'Uploaded  Logo Key'}
                name={'uploadlogo key'}
                required={true}
              />
              <MediaRow list={logo} onDelete={handleDeletelogo} />
            </>
          )}
          {!homeIcon?.length ? (
            <>
              <FormLabel
                label={'Home IconKey'}
                name={'HomeIconKey'}
                required={true}
              />
              <ImageUpload
                onFileUploaded={handleHomeIconChange}
                postUrl="/app-themes/upload"
                postBody={{
                  subType: 'home-icons',
                  tenantId: watch('tenantId'),
                }}
              />
            </>
          ) : (
            <>
              <FormLabel
                label={'Uploaded  Home IconKey'}
                name={'HomeIconKey key'}
                required={false}
              />
              <MediaRow list={homeIcon} onDelete={handleDeleteHomeIcon} />
            </>
          )}

          <FormTextField
            label={'Contact Url'}
            name={'contactUrl'}
            control={control}
          />
          <FormTextField
            label={'Privacy PolicyUrl'}
            name={'privacyPolicyUrl'}
            control={control}
          />
          <FormTextField
            label={'Feedback Url'}
            name={'feedbackUrl'}
            control={control}
          />

          <FormTextField
            label={'About Url'}
            name={'aboutUrl'}
            control={control}
          />
          <FormTextField
            label={'Android AppId'}
            name={'androidAppId'}
            control={control}
          />
          <FormTextField
            label={'Ios AppId'}
            name={'iosAppId'}
            control={control}
          />
          <FormTextField
            label={'College AppName'}
            name={'collegeAppName'}
            control={control}
            required
          />
          <FormTextField
            label={'College ShortName'}
            name={'collegeShortName'}
            control={control}
            required
          />
          <FormTextField
            label={'College Name'}
            name={'collegeName'}
            control={control}
            required
          />

          <Stack direction="row" gap={2} mt={2}>
            <ActionButton type="submit" fullWidth loading={isLoading}>
              {data ? 'Update App ' : 'Create App '}
            </ActionButton>
            <ActionButton
              variant="outlined"
              fullWidth
              onClick={() => hideDialog()}
            >
              Cancel
            </ActionButton>
          </Stack>
        </Stack>
      </form>
    </div>
  )
}

export default AppForm

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: alpha('#121212', 0.5),
  lineHeight: 2,
  '& span': {
    color: theme.palette.error.main,
  },
}))
