import {
  PageContent,
  PageHeader,
  SearchBar,
  SingleSelect,
  Table,
} from '@campxdev/shared'
import { useQuery } from 'react-query'
import { SERVICES } from 'services'
import { useImmer } from 'use-immer'

function Faculty() {
  const { data: tenants, isLoading: loadingTenants } = useQuery(
    'all-tenants',
    SERVICES.tenant.fetchAll,
  )

  const [state, setState] = useImmer({
    limit: 10,
    offset: 0,
    tenantId: null,
    noParentMobile: false,
    page: 0,
    search: null,
    type: 'staff_user',
  })

  const { data, isLoading } = useQuery(['allFaculty', state], () =>
    SERVICES.users.fetchUsers({
      limit: state.limit,
      offset: state.offset,
      tenantId: state.tenantId,
      noParentMobile: state.noParentMobile,
      search: state.search,
      type: state.type,
    }),
  )

  const columns = [
    {
      title: 'Employee Id',
      dataIndex: 'employeeId',
      key: 'employeeId',
      render: (_, row) => (row?.employeeId ? row?.employeeId : '-'),
    },
    {
      title: 'User ID',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'workPhoneNumber',
      key: 'workPhoneNumber',
      render: (_, row) => (row?.workPhoneNumber ? row?.workPhoneNumber : '-'),
    },

    {
      title: 'College Name',
      dataIndex: 'tenant.name',
      key: 'tenant.name',
    },
    {
      title: 'Institution Name',
      dataIndex: 'tenant.subDomain',
      key: 'tenant.subDomain',
    },
  ]

  const handlePagination = (value: number) => {
    setState((s) => {
      s.offset = value >= 1 ? value - 1 : 0
      s.page = value - 1
    })
  }
  const handlePageLimit = (value: number) => {
    setState((s) => {
      s.limit = value
      s.offset = 0
    })
  }

  return (
    <>
      <PageHeader
        title="All Faculty"
        actions={[
          <SingleSelect
            containerProps={{
              sx: { minWidth: '200px' },
            }}
            key="1"
            options={tenants?.result?.map((tenant) => ({
              label: tenant.name,
              value: tenant._id,
            }))}
            firstItemEmpty
            value={state.tenantId ?? '__empty__'}
            onChange={(e) => {
              setState((s) => {
                s.tenantId =
                  e.target.value === '__empty__' ? null : e.target.value
              })
            }}
          />,
        ]}
      />
      <PageContent>
        <SearchBar
          onSearch={(e) => {
            setState((s) => {
              s.search = e
              s.offset = 0
              s.page = 0
            })
          }}
          textFieldProps={{
            placeholder: 'Search Faculty by Name / Email / user Id ',
            containerProps: {
              width: '500px',
              margin: '16px 0',
            },
          }}
        />
        <Table
          columns={columns}
          dataSource={data?.data}
          loading={false}
          pagination={{
            totalCount: data?.count || 0,
            page: state.page,
            limit: state.limit,
            onChange: handlePagination,
            onChangeLimit: handlePageLimit,
          }}
        />
      </PageContent>
    </>
  )
}

export default Faculty
